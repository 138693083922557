/**
 * @constant themeOverrides [declarable]
 * @description This file includes all NaiveUI theme overrides
 * @see {@link https://www.naiveui.com/en-US/dark/docs/customize-theme}
 * @see {@link https://www.naiveui.com/en-US/dark/components/config-provider}
 */
const themeOverrides = {
  common: {
    borderRadius: '6px',
    scrollbarWidth: '4px',
    scrollbarHeight: '4px',
    scrollbarBorderRadius: '2px',
    scrollbarColor: 'rgba(0,0,0,.15)',
    scrollbarColorHover: 'rgba(0,0,0,.25)',
    primaryColor: '#B0DEBD',
    primaryColorHover: '#c0e5ca',
    primaryColorPressed: '#9ec8aa',
    primaryColorSuppl: '#c0e5ca',
    infoColor: '#2EA2CC',
    infoColorHover: '#58b5d6',
    infoColorPressed: '#2992b8',
    infoColorSuppl: '#58b5d6',
    successColor: '#78B873',
    successColorHover: '#93c68f',
    successColorPressed: '#6ca668',
    successColorSuppl: '#93c68f',
    warningColor: '#FFBA00',
    warningColorHover: '#ffc833',
    warningColorPressed: '#e6a700',
    warningColorSuppl: '#ffc833',
    errorColor: '#E43B3B',
    errorColorHover: '#e96262',
    errorColorPressed: '#cd3535',
    errorColorSuppl: '#e96262',
  },
  Avatar: {
    heightSmall: '32px',
    heightMedium: '42px',
    heightHuge: '64px',
    borderRadius: '6px',
  },
  Button: {
    borderRadiusTiny: '3px',
    borderRadiusSmall: '4px',
    borderRadiusMedium: '6px',
    borderRadiusLarge: '8px',
    fontWeight: 500,
    textColorHover: '#000000',
    textColorPrimary: '#202631',
    textColorHoverPrimary: '#202631',
    textColorPressedPrimary: '#202631',
    textColorFocusPrimary: '#202631',
    colorPrimary: '#B0DEBD',
    colorHoverPrimary: '#c0e5ca',
    colorPressedPrimary: '#9ec8aa',
    colorFocusPrimary: '#c0e5ca',
    colorDisabledPrimary: '#b5d9bf',
    colorInfo: '#2EA2CC',
    colorHoverInfo: '#58b5d6',
    colorPressedInfo: '#2992b8',
    colorFocusInfo: '#58b5d6',
    colorDisabledInfo: '#3e9bbc',
    colorSuccess: '#78B873',
    colorHoverSuccess: '#93c68f',
    colorPressedSuccess: '#6ca668',
    colorFocusSuccess: '#93c68f',
    colorDisabledSuccess: '#7eb17a',
    colorWarning: '#FFBA00',
    colorHoverWarning: '#ffc833',
    colorPressedWarning: '#e6a700',
    colorFocusWarning: '#ffc833',
    colorDisabledWarning: '#e6ae1a',
    colorError: '#E43B3B',
    colorHoverError: '#e96262',
    colorPressedError: '#cd3535',
    colorFocusError: '#e96262',
    colorDisabledError: '#d34c4c',
  },
  Card: {
    borderRadius: '6px',
  },
  Form: {
    feedbackHeightMedium: '28px',
    feedbackFontSizeMedium: '13px',
    feedbackPadding: '2px 0 8px 0',
  },
  Input: {
    paddingLarge: '0 18px',
    borderRadius: '6px'
  },
  LoadingBar: {
    colorLoading: '#6a8571',
  },
  Progress: {
    fillColorInfo: '#2EA2CC',
    fillColorSuccess: '#78B873',
    fillColorWarning: '#FFBA00',
    fillColorError: '#E43B3B',
  },
  Skeleton: {
    borderRadius: '6px',
  },
  Space: {
    gapSmall: '8px 12px',
    gapMedium: '12px 16px',
    gapLarge: '16px 20px',
  },
  Typography: {
    aTextColor: '#586f5f',
  },
}

export default themeOverrides
